import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import BestSellingProducts from "./BestSellingProducts";
import ProfitLossChart from "./ProfitLossChart";
import Section from "./Section";
import StoreVisits from "./StoreVisits";
import TopSellers from "./TopSellers";
import { Helmet } from "react-helmet";
import { ShowOverviewData } from "../../store/overview/action";
//test websocket in this page first

const DashboardOverview = () => {
    const [overviewData, setOverviewData] = useState()
    //connect websocket and update via redux store ?
    //useselector or connect ?

    //fetch on useeffect
    useEffect(() => {
        async function FetchData() {
            try {
                const result = await ShowOverviewData()
                console.log(result);
                setOverviewData(result)
            } catch (error) {
                console.log(error);
            }
        }
        FetchData()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>Overview - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <Section />
                                <Row>
                                    <Widget data={overviewData} />
                                </Row>
                                <Row>
                                    <Col xl={8}>
                                        <ProfitLossChart data={overviewData ? overviewData.ProfitLossSeries : null} />
                                    </Col>
                                    <StoreVisits />
                                </Row>
                                <Row>
                                    <BestSellingProducts />
                                    <TopSellers />
                                </Row>
                                {/* <Row>
                                    <RecentOrders />
                                </Row> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardOverview;

import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardBody } from 'reactstrap'

function TableTrader() {

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">Balance Transaction List</h4>
            </CardHeader>

            <CardBody>
                <div id="customerList">
                    <div className="table-responsive table-card mb-1">
                        <table className="table align-middle table-nowrap" id="customerTable">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col" style={{ width: "50px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                        </div>
                                    </th>
                                    <th className="sort" data-sort="customer_name">Trader ID</th>
                                    <th className="sort" data-sort="email">Email</th>
                                    <th className="sort" data-sort="phone">Phone</th>
                                    <th className="sort" data-sort="date">Event</th>
                                    <th className="sort" data-sort="status">Method</th>
                                    <th className="sort" data-sort="action">Before Adjust</th>
                                    <th className="sort" data-sort="action">Amount</th>
                                    <th className="sort" data-sort="action">After Adjust</th>
                                    <th className="sort" data-sort="action">Updated When</th>
                                </tr>
                            </thead>
                            <tbody className="list form-check-all">
                                <tr>
                                    <th scope="row">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                        </div>
                                    </th>
                                    <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2101</Link></td>
                                    <td className="customer_name">1241234123123123</td>
                                    <td className="email">marycousar@velzon.com</td>
                                    <td className="phone">580-464-4694</td>
                                    <td className="date">Deposit</td>
                                    <td className="date text-success">Add</td>
                                    <td className="date">1000</td>
                                    <td className="date">100</td>
                                    <td className="date">1100</td>
                                    <td className="date">Today</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                            <div className="text-center">
                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                </lord-icon>
                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                    orders for you search.</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="pagination-wrap hstack gap-2">
                            <Link className="page-item pagination-prev disabled" to="#">
                                Previous
                            </Link>
                            <ul className="pagination listjs-pagination mb-0"></ul>
                            <Link className="page-item pagination-next" to="#">
                                Next
                            </Link>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default TableTrader
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import TableTrader from './TableTrader.jsx';
import Filter from "./filter";
import Nav from './nav'
import { getAlltraders } from '../../../api/trader.jsx'

const DashboardTraderManagement = () => {
    const [traders, setTraders] = useState([]),
        [loading, setLoading] = useState(false)

    async function getTraders() {
        setLoading(true)
        try {
            const result = await getAlltraders()
            setTraders(result)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getTraders()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>Trader - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Nav />
                        </Col>
                        <Col lg={12}>
                            <Filter />
                        </Col>
                        <Col lg={12}>
                            <TableTrader traders={traders} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DashboardTraderManagement;

import React from "react";
import { Helmet } from "react-helmet";
import Revenue from './heatmap';
const TradeHeatmap = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Trade Heatmap - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <Revenue />
            </div>
        </React.Fragment>
    );
};

export default TradeHeatmap;

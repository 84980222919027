import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardBody } from 'reactstrap'
import commanumber from 'comma-number'
import { toast, ToastContainer } from 'react-toastify'

function TableTrader({ traders }) {

    async function copyClipboard(text) {
        try {
            await navigator.clipboard.writeText(text)
            toast("Copied !", { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' })
        } catch (error) {
            console.log(error);
        }
    }

    let render_traders = traders.map((item) => (
        <tr key={item.id}>
            <th scope="row">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                </div>
            </th>
            <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#</Link></td>
            <td className="customer_name">
                <i onClick={() => copyClipboard(item.id)} style={{ cursor: "pointer" }} className="ri-file-copy-line"></i> {item.id}
            </td>
            <td className="email">{item.email}</td>
            <td className="phone">{item.phone}</td>
            <td className="date">{commanumber(Number(item.balance).toFixed(2))}</td>
            <td className="date text-success">{commanumber(Number(item.demo_balance).toFixed(2))}</td>
            <td className="date">{item.status}</td>
            <td className="date">{item.preferred_lang}</td>
            <td className="date">{item.preferred_currency}</td>
            <td className="date">{item.created_at}</td>
        </tr>
    ))

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">Trader List</h4>
            </CardHeader>
            <ToastContainer autoClose={1000} />
            <CardBody>
                <div id="customerList">
                    <div className="table-responsive table-card mb-1">
                        <table className="table align-middle table-nowrap" id="customerTable">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col" style={{ width: "50px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                        </div>
                                    </th>
                                    <th className="sort" data-sort="customer_name">Trader ID</th>
                                    <th className="sort" data-sort="email">Email</th>
                                    <th className="sort" data-sort="phone">Phone</th>
                                    <th className="sort" data-sort="date">Balance</th>
                                    <th className="sort" data-sort="status">Demo Balance</th>
                                    <th className="sort" data-sort="action">Status</th>
                                    <th className="sort" data-sort="action">Preffered Language</th>
                                    <th className="sort" data-sort="action">Preffered Currency</th>
                                    <th className="sort" data-sort="action">Created At</th>
                                </tr>
                            </thead>
                            <tbody className="list form-check-all">
                                {render_traders}
                            </tbody>
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                            <div className="text-center">
                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                </lord-icon>
                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                    orders for you search.</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="pagination-wrap hstack gap-2">
                            <Link className="page-item pagination-prev disabled" to="#">
                                Previous
                            </Link>
                            <ul className="pagination listjs-pagination mb-0"></ul>
                            <Link className="page-item pagination-next" to="#">
                                Next
                            </Link>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default TableTrader
import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';


const Widgets = ({ data }) => {
    const WidgetData = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Trading Volume",
            // badge: "ri-arrow-right-up-line",
            badgeClass: "muted",
            percentage: "0.00",
            counter: data ? data.Total_Trading_Volume : 0.00,
            link: "View Trading",
            bgcolor: "success",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            separator: ",",
            prefix: "฿",
            suffix: ""
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Net Transaction",
            // badge: "ri-arrow-right-down-line",
            badgeClass: "muted",
            percentage: "0.00",
            counter: "0",
            link: "View Transaction",
            bgcolor: "info",
            icon: "bx bx-shopping-bag",
            decimals: 2,
            separator: ",",
            prefix: "฿",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "info",
            label: "Total Balance",
            badgeClass: "muted",
            percentage: "0.00",
            counter: data ? data.Total_Balance : 0.00,
            link: "View All User",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            separator: ",",
            prefix: "฿",
            suffix: ""
        },
        {
            id: 4,
            cardColor: "success",
            label: "New User",
            // badge: "ri-arrow-right-up-line",
            badgeClass: "muted",
            percentage: "0.00",
            counter: data ? data.Users : 0,
            link: "View All User",
            bgcolor: "warning",
            icon: "bx bx-user-circle",
            decimals: 0,
            prefix: "",
            suffix: ""
        },
    ];
    return (
        <React.Fragment>
            {WidgetData.map((item, key) => (
                <Col xl={3} md={6} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                                        {item.badge ? <i className={"fs-13 align-middle " + item.badge}></i> : null} {item.percentage} %
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={item.prefix}
                                            suffix={item.suffix}
                                            separator={item.separator}
                                            end={item.counter}
                                            decimals={item.decimals}
                                            duration={1}
                                        />
                                    </span></h4>
                                    <Link to="#" className="text-decoration-underline">{item.link}</Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-soft-" + item.bgcolor}>
                                        <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>))}
        </React.Fragment>
    );
};

export default Widgets;
import React, { useState } from 'react'
import { Card, CardBody, Label, Col, Input, Row } from 'reactstrap'
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

export default function Filter() {
    const [selected, setSelected] = useState(null)

    function handleMulti3(selectedMulti3) {
        setSelected(selectedMulti3);
    }

    const GroupOptions = [

        {
            label: "UK",
            options: [
                { label: "London", value: "London" },
                { label: "Manchester", value: "Manchester" },
                { label: "Liverpool", value: "Liverpool" }
            ]
        },
        {
            label: "FR",
            options: [
                { label: "Paris", value: "Paris" },
                { label: "Lyon", value: "Lyon" },
                { label: "Marseille", value: "Marseille" }
            ]
        },
        {
            label: "DE",
            options: [
                { label: "Hamburg", value: "Hamburg" },
                { label: "Munich", value: "Munich" },
                { label: "Berlin", value: "Berlin" }
            ]
        },
        {
            label: "US",
            options: [
                { label: "New York", value: "New York" },
                { label: "Washington", value: "Washington" },
                { label: "Michigan", value: "Michigan" }
            ]
        },
        {
            label: "SP",
            options: [
                { label: "Madrid", value: "Madrid" },
                { label: "Barcelona", value: "Barcelona" },
                { label: "Malaga", value: "Malaga" }
            ]
        },
        {
            label: "CA",
            options: [
                { label: "Montreal", value: "Montreal" },
                { label: "Toronto", value: "Toronto" },
                { label: "Vancouver", value: "Vancouver" }
            ]
        }

    ];

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col lg={4} md={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                Wallet ID
                            </Label>
                            <Input type="password" className="form-control" id="placeholderInput" placeholder="Wallet ID" />
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                Address
                            </Label>
                            <Input type="password" className="form-control" id="placeholderInput" placeholder="Address" />
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                Status
                            </Label>
                            <Input type="password" className="form-control" id="placeholderInput" placeholder="Status" />
                        </div>
                    </Col>
                    <Col lg={12} className='text-end'>
                        <button className='btn btn-success'>Search</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

import React, { useState } from "react";
import { Col, Container, Row, Form, Label, Input, Card, CardBody, CardHeader, Button, Spinner } from "reactstrap";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { addTraderRealBalance, deductTraderRealBalance } from '../../../../api/trader'
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdjustTraderBalance = () => {

    const [method, setMethod] = useState(null),
        [user_id, setUserId] = useState(""),
        [amount, setAmount] = useState(0),
        [loading, setLoading] = useState(false)

    const navigate = useNavigate()


    function handleSelect(selectedOption) {
        setMethod(selectedOption);
    }

    async function onSubmitAdjustTraderBalance(e) {
        e.preventDefault()
        setLoading(true)
        console.log(method.value);
        switch (method.value) {
            case "add":
                try {
                    const body = {
                        user_id,
                        amount: Number(amount),
                    }
                    const result = await addTraderRealBalance(body)
                    console.log(result);
                    toast("Success Adjust Trader Balance !", { position: "top-right", className: 'bg-primary text-white' })
                    setLoading(false)
                    navigate("/trader")
                } catch (error) {
                    console.log(error);
                    setLoading(false)
                }
                return;
            case "deduct":
                try {
                    const body = {
                        user_id,
                        amount: Number(amount),
                    }
                    const result = await deductTraderRealBalance(body)
                    toast("Success Adjust Trader Balance !", { position: "top-right", className: 'bg-primary text-white' })
                    setLoading(false)
                    navigate("/trader")
                } catch (error) {
                    console.log(error);
                    setLoading(false)
                }
                return;
        }
    }

    const MethodOptions = [
        { value: 'add', label: 'Add' },
        { value: 'deduct', label: 'Deduct' }
    ];


    return (
        <React.Fragment>
            <Helmet>
                <title>Adjust Trader Balance - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <ToastContainer autoClose={5000} />
                <Container fluid>
                    <Card className="col-lg-6 col-md-12">
                        <CardHeader>
                            <h4 className="card-title mb-0">Adjust Trader Balance</h4>
                        </CardHeader>

                        <CardBody>
                            <Form onSubmit={onSubmitAdjustTraderBalance}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="firstNameinput" className="form-label">Trader ID</Label>
                                            <Input type="text" value={user_id} onChange={(e) => setUserId(e.target.value)} className="form-control" placeholder="Enter your firstname" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Method
                                            </Label>
                                            <Select
                                                value={method}
                                                onChange={handleSelect}
                                                options={MethodOptions}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="phonenumberInput" className="form-label">Amount</Label>
                                            <Input type="number" step="0.01" value={amount} onChange={(e => setAmount(e.target.value))} className="form-control" placeholder="Amount" id="phonenumberInput" />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        {loading ? (
                                            <div className="text-end">
                                                <Button color="primary" className="btn-load" outline>
                                                    <span className="d-flex align-items-center">
                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                        <span className="flex-grow-1 ms-2">
                                                            Loading...
                                                        </span>
                                                    </span>
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default AdjustTraderBalance;

import axios from 'axios'

export async function CreateWallet(body) {
    try {
        const result = await axios.post('/v1/cryptocurrency/admin/wallet', body)
        return result
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function ListWallet() {
    try {
        const result = await axios.get('/v1/cryptocurrency/admin/wallet')
        return result
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function UpdateWallets(id, body) {
    try {
        const result = await axios.patch(`/v1/cryptocurrency/admin/wallet/isactive/${id}`, body)
        return result
    } catch (error) {
        return Promise.reject(error)
    }
}
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import TableTrader from './TableTrader.jsx';
import Filter from "./filter";
import Nav from './nav'

const DashboardTraderBalance = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Trader - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Nav />
                        </Col>
                        <Col lg={12}>
                            <Filter />
                        </Col>
                        <Col lg={12}>
                            <TableTrader />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DashboardTraderBalance;

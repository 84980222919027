import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data

    //Dashbaord
    const [isOverview, setIsOverview] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    //Trade
    const [isPosition, setIsPosition] = useState(false);
    const [isOrder, setIsOrder] = useState(false);
    //Group
    const [isTrader, setIsTrader] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    //Securities
    const [isForex, setIsForex] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isStock, setIsTStock] = useState(false);
    const [isCommodities, setIsCommodities] = useState(false);
    //Marketing
    const [isPromotion, setIsPromotion] = useState(false);
    const [isBonus, setIsBonus] = useState(false);
    const [isRebate, setIsRebate] = useState(false);
    //Payment
    const [isPaymentGateway, setIsPaymentGateway] = useState(false);
    const [isCryptocurrency, setIsCryptocurrency] = useState(false);
    const [isTransaction, setIsTransaction] = useState(false);
    //Business Partner
    const [isIB, setIsIB] = useState(false);
    const [isAffiliate, setIsAffiliate] = useState(false);
    const [isWhitelabel, setIsWhitelabel] = useState(false);
    const [isAPI, setIsAPI] = useState(false);


    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsOverview(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isOverview,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Dashboard",
            isHeader: true,
        },
        {
            id: "overview",
            label: "Overview",
            icon: "ri-dashboard-2-line",
            link: "/",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Overview');
            }
        },
        {
            id: "heatmap",
            label: "Trade Heatmap",
            icon: "ri-bubble-chart-fill",
            link: "/heatmap",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Heatmap');
            }
        },
        {
            label: "Payment",
            isHeader: true,
        },
        {
            id: "paymentgateway",
            label: "Payment Gateway",
            icon: "ri-coin-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPaymentGateway(!isPaymentGateway);
                setIscurrentState('Paymentgateway');
                updateIconSidebar(e);
            },
            stateVariables: isPaymentGateway,
            subItems: [
                { id: "pg_wallet", label: "Payment Gateway Wallet", link: "/wallet", parentId: "paymentgateway" },
                { id: "deposit_management", label: "Deposit Management", link: "/deposit", parentId: "paymentgateway" },
                { id: "transfer_inout", label: "Transfer In-Out", link: "/transfer-inout", parentId: "paymentgateway" },
                { id: "transfer_inout_log", label: "Transfer In-Out Log", link: "/transfer-inout-log", parentId: "paymentgateway" },
            ],
        },
        {
            id: "cryptocurrency",
            label: "Cryptocurrency",
            icon: "ri-wallet-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCryptocurrency(!isCryptocurrency);
                setIscurrentState('Cryptocurrency');
                updateIconSidebar(e);
            },
            stateVariables: isCryptocurrency,
            subItems: [
                { id: "crypto_wallet", label: "Crypto Wallet Management", link: "/crypto/wallet", parentId: "cryptocurrency" },
                { id: "deposit_management", label: "Deposit Management", link: "/crypto/deposit", parentId: "cryptocurrency" },
                { id: "withdrawn_management", label: "Withdrawn Management", link: "/crypto/withdrawn", parentId: "cryptocurrency" },
                { id: "transfer_inout", label: "Transfer In-Out", link: "/cyrpto/transfer-inout", parentId: "cryptocurrency" },
                { id: "transfer_inout_log", label: "Transfer In-Out Log", link: "/crypto/transfer-inout/log", parentId: "cryptocurrency" },
                { id: "crypto_deposit_log", label: "Deposit Log", link: "/crypto/deposit/log", parentId: "cryptocurrency" },
                { id: "crypto_withdrawal_log", label: "Withdrawal Log", link: "/crypto/withdrawal/log", parentId: "cryptocurrency" }
            ],
        },
        // {
        //     id: "auto",
        //     label: "AutoPay",
        //     icon: "ri-wallet-fill",
        //     link: "/#",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsTransaction(!isTransaction);
        //         setIscurrentState('Transaction');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isTransaction,
        //     subItems: [
        //         { id: "wallet", label: "Wallet Management", link: "/wallet", parentId: "auto" },
        //         { id: "bank_account", label: "Bank Account Management", link: "/bankaccount", parentId: "auto" },
        //         { id: "deposit_automate", label: "Deposit Automating", link: "/depositauto", parentId: "auto" },
        //         { id: "transfer_inout", label: "Transfer In-Out", link: "/transfer-inout", parentId: "auto" },
        //         { id: "auto_withdrawal_log", label: "Auto Withdrawal Log", link: "/withdrawal-log", parentId: "auto" },
        //         { id: "autopay_error_log", label: "AutoPay Erorr Log", link: "/autopay-erorr", parentId: "auto" },
        //         { id: "bank_deposit_statement", label: "Bank Deposit Statement", link: "/deposit-statement", parentId: "auto" },
        //         { id: "bank_withdrawal_statement", label: "Bank Withdrawal Statement", link: "/withdrawal-statement", parentId: "auto" },
        //         { id: "bank_message_log", label: "Bank Message Log", link: "/bankmessage", parentId: "auto" }
        //     ],
        // },
        {
            label: "User",
            isHeader: true,
        },
        {
            id: "trader",
            label: "Trader",
            icon: "ri-user-3-line",
            link: "/#",
            click: function (e) {
                setIsTrader(!isTrader);
                e.preventDefault();
                setIscurrentState('Trader');
                updateIconSidebar(e);
            },
            stateVariables: isTrader,
            subItems: [
                { id: "trader_management", label: "Trader Management", link: "/trader", parentId: "trader" },
                { id: "balance_management", label: "Balance Management", link: "/trader/balance", parentId: "trader" },
                { id: "Trader Securities Management", label: "    - Trader Securities Management", link: "/trader-securities", parentId: "trader" },
                { id: "trader_login_log", label: "Trader Login Log", link: "/trader-log", parentId: "trader" }
            ],
        },
        {
            id: "admin",
            label: "Admin",
            icon: "ri-admin-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAdmin(!isAdmin);
                setIscurrentState('Auth');
                updateIconSidebar(e);
            },
            stateVariables: isAdmin,
            subItems: [
                { id: "admin_management", label: "Admin Management", link: "/admin", parentId: "admin" },
                { id: "admin_announcement", label: "Announcement", link: "/admin-announcement", parentId: "admin" },
                { id: "admin_access_log", label: "Access Log", link: "/admin-log", parentId: "admin" }
            ],
        },
        {
            label: "Trade",
            isHeader: true,
        },
        {
            id: "position",
            label: "Position",
            icon: "ri-money-dollar-box-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPosition(!isPosition);
                setIscurrentState('Position');
                updateIconSidebar(e);
            },
            stateVariables: isPosition,
            subItems: [
                { id: "posiition_management", label: "Position Management", link: "/position", parentId: "position" },
                { id: "position_log", label: "Position Log", link: "/position-log", parentId: "position" }
            ],
        },
        {
            id: "order",
            label: "Order",
            icon: "ri-coupon-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsOrder(!isOrder);
                setIscurrentState('Order');
                updateIconSidebar(e);
            },
            stateVariables: isOrder,
            subItems: [
                { id: "order_management", label: "Order Management", link: "/order", parentId: "order" },
                { id: "order_log", label: "Order Trade Log", link: "/order-log", parentId: "order" }
            ],
        },
        {
            label: "Marketing",
            isHeader: true,
        },
        {
            id: "promotion",
            label: "Promotion",
            icon: "ri-exchange-funds-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPromotion(!isPromotion);
                setIscurrentState('Promotion');
                updateIconSidebar(e);
            },
            stateVariables: isBonus,
            subItems: [
                { id: "list", label: "List", link: "/promotion", parentId: "promotion" }
            ],
        },
        {
            id: "bonus",
            label: "Bonus",
            icon: "ri-exchange-funds-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsBonus(!isBonus);
                setIscurrentState('Bonus');
                updateIconSidebar(e);
            },
            stateVariables: isBonus,
            subItems: [
                { id: "list", label: "List", link: "/bonus", parentId: "bonus" }
            ],
        },
        {
            id: "rebate",
            label: "Rebate",
            icon: "ri-exchange-funds-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsBonus(!isBonus);
                setIscurrentState('Rebate');
                updateIconSidebar(e);
            },
            stateVariables: isBonus,
            subItems: [
                { id: "list", label: "List", link: "/rebate", parentId: "rebate" }
            ],
        },
        {
            label: "Securites and Pair",
            isHeader: true,
        },
        {
            id: "forex",
            label: "Forex",
            icon: "ri-exchange-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsForex(!isForex);
                setIscurrentState('Forex');
                updateIconSidebar(e);
            },
            stateVariables: isForex,
            subItems: [
                { id: "list", label: "List", link: "/forex", parentId: "forex" }
            ],
        },
        {
            id: "crypto",
            label: "Cryptocurrency",
            icon: "ri-bit-coin-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCrypto(!isCrypto);
                setIscurrentState('Cryptocurrency');
                updateIconSidebar(e);
            },
            stateVariables: isCrypto,
            subItems: [
                { id: "list", label: "List", link: "/crypto", parentId: "crypto" }
            ],
        },
        {
            id: "stock",
            label: "Stock",
            icon: "ri-stock-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsTStock(!isStock);
                setIscurrentState('Stock');
                updateIconSidebar(e);
            },
            stateVariables: isStock,
            subItems: [
                { id: "list", label: "List", link: "/stock", parentId: "stock" }
            ],
        },
        {
            id: "commodities",
            label: "Commodities",
            icon: "ri-water-flash-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCommodities(!isCommodities);
                setIscurrentState('Commodities');
                updateIconSidebar(e);
            },
            stateVariables: isCommodities,
            subItems: [
                { id: "list", label: "List", link: "/commodities", parentId: "commodities" }
            ],
        },
        {
            label: "Business Partners",
            isHeader: true,
        },
        {
            id: "affiliate",
            label: "Affiliate",
            icon: "ri-group-2-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAffiliate(!isAffiliate);
                setIscurrentState('Affiliate');
                updateIconSidebar(e);
            },
            stateVariables: isAffiliate,
            subItems: [
                { id: "list", label: "List", link: "/affiliate", parentId: "affiliate" }
            ],
        },
        {
            id: "ib",
            label: "IB",
            icon: "ri-team-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsIB(!isIB);
                setIscurrentState('IB');
                updateIconSidebar(e);
            },
            stateVariables: isIB,
            subItems: [
                { id: "list", label: "List", link: "/ib", parentId: "ib" }
            ],
        },
        {
            id: "whitelabel",
            label: "Whitelabel",
            icon: "ri-pencil-ruler-2-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsWhitelabel(!isWhitelabel);
                setIscurrentState('Whitelabel');
                updateIconSidebar(e);
            },
            stateVariables: isWhitelabel,
            subItems: [
                { id: "list", label: "List", link: "/whitelabel", parentId: "whitelabel" }
            ],
        },
        {
            id: "api",
            label: "API",
            icon: "ri-code-s-slash-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAPI(!isAPI);
                setIscurrentState('API');
                updateIconSidebar(e);
            },
            stateVariables: isAPI,
            subItems: [
                { id: "list", label: "List", link: "/api", parentId: "api" }
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { BasicHeatmap } from '../Charts/ApexCharts//HeatmapCharts/HeatmapCharts';


const Revenue = () => {
    //   const dispatch = useDispatch();

    //   const [chartData, setchartData] = useState([]);

    //   const { revenueData } = useSelector(state => ({
    //     revenueData: state.DashboardEcommerce.revenueData
    //   }));

    //   useEffect(() => {
    //     setchartData(revenueData);
    //   }, [revenueData]);

    //   const onChangeChartPeriod = pType => {
    //     dispatch(getRevenueChartsData(pType));
    //   };

    //   useEffect(() => {
    //     dispatch(getRevenueChartsData("all"));
    //   }, [dispatch]);
    return (
        <React.Fragment>
            <Card>
                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <CountUp start={0} end={7585} duration={3} separator="," />
                                </h5>
                                <p className="text-muted mb-0">Positions</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <CountUp
                                        suffix="k"
                                        prefix="$"
                                        start={0}
                                        decimals={2}
                                        end={22.89}
                                        duration={3}
                                    />
                                </h5>
                                <p className="text-muted mb-0">Earnings</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <CountUp start={0} end={367} duration={3} />
                                </h5>
                                <p className="text-muted mb-0">Spread</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0 border-end-0">
                                <h5 className="mb-1 text-success">
                                    <CountUp
                                        start={0}
                                        end={18.92}
                                        decimals={2}
                                        duration={3}
                                        suffix="%"
                                    />
                                </h5>
                                <p className="text-muted mb-0">Win/Loss Ratio</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div className="w-100">
                        <div dir="ltr">
                            <BasicHeatmap dataColors='["--vz-success", "--vz-card-custom"]' />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Revenue;

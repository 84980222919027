import { toast } from "react-toastify";



export async function copyClipboard(text) {
    try {
        await navigator.clipboard.writeText(text)
        toast("Copied !", { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' })
    } catch (error) {
        console.log(error);
    }
}

import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";

const DashboardPosition = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Position - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardPosition;

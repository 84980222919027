import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import TableTrader from './TableTrader.jsx';
import Filter from "./filter";
import Nav from './nav'
import Breadcrump from '../../../components/Common/BreadCrumb.js'
import { ListWallet } from '../../../api/wallet.jsx'
import { ToastContainer } from "react-toastify";

const CryptoWalletManagement = () => {
    const [wallets, setWallets] = useState([]),
        [loading, setLoading] = useState(false)

    async function getWallets() {
        setLoading(true)
        try {
            const result = await ListWallet()
            setWallets(result)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getWallets()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>Crypto Wallet Management - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <ToastContainer autoClose={3000} />
                <Container fluid>
                    <Breadcrump title={"Crypto Wallet Management"} />
                    <Row>
                        <Col lg={12}>
                            <Nav />
                        </Col>
                        <Col lg={12}>
                            <Filter />
                        </Col>
                        <Col lg={12}>
                            <TableTrader wallets={wallets} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CryptoWalletManagement;

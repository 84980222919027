import axios from "axios";

export async function getAlltraders() {
    try {
        const result = await axios.get('/v1/user/admin')
        return result
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function addTraderRealBalance(body) {
    try {
        const result = await axios.patch('/v1/user/balance/real/add', body)
        return result
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function deductTraderRealBalance(body) {
    try {
        const result = await axios.patch('/v1/user/balance/real/deduct', body)
        return result
    } catch (error) {
        return Promise.reject(error)
    }
}
import axios from "axios"
import { setAuthToken } from '../middleware/setAuthToken'

export async function Login(body) {
    try {
        const { Token } = await axios.post('/v1/admin/login', body)

        // localstorage
        localStorage.setItem('jwt', "Bearer " + Token);
        //set token ot axios
        setAuthToken("Bearer " + Token);

        return Token
    } catch (error) {
        console.log(error, "error");
        return Promise.reject(error)
    }
}
import React, { useState } from "react";
import { Col, Container, Row, Form, Label, Input, Card, CardBody, Button, Spinner } from "reactstrap";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { CreateWallet } from '../../../api/wallet'

import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const CryptoAddWallet = () => {

    const [network, setNetwork] = useState(null),
        [address, setAddress] = useState(""),
        [files, setFiles] = useState([]),
        [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    function handleSelect(selectedOption) {
        setNetwork(selectedOption);
    }

    async function onSubmitAddWallet(e) {
        e.preventDefault()
        setLoading(true)
        try {
            const form = new FormData()

            form.append("network", network.value)
            form.append("address", address)
            form.append("qrcode", files[0].file)

            await CreateWallet(form)
            toast("Create New Wallet Success !", { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' })
            navigate("/crypto/wallet")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast(error, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' })
        }
    }

    const NetworkOption = [
        { value: 'eth', label: 'Ethereum' }
    ];


    return (
        <React.Fragment>
            <Helmet>
                <title>Add Crypto Wallet - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <ToastContainer autoClose={5000} />
                <Container fluid>
                    <BreadCrumb title={"Add Crypto Wallet"} pageTitle={"Crypto Wallet"} link={"/crypto/wallet"} />
                    <Card className="col-lg-6 col-md-12">
                        <CardBody>
                            <Form onSubmit={onSubmitAddWallet}>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Network
                                            </Label>
                                            <Select
                                                value={network}
                                                onChange={handleSelect}
                                                options={NetworkOption}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="firstNameinput" className="form-label">Address</Label>
                                            <Input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control" placeholder="Enter your firstname" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <Label htmlFor="firstNameinput" className="form-label">QR Code Wallet Address</Label>
                                        <FilePond
                                            files={files}
                                            onupdatefiles={setFiles}
                                            allowMultiple={false}
                                            maxFiles={3}
                                            name="files"
                                            className="filepond filepond-input-multiple"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        {loading ? (
                                            <div className="text-end">
                                                <Button color="primary" className="btn-load" outline>
                                                    <span className="d-flex align-items-center">
                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                        <span className="flex-grow-1 ms-2">
                                                            Loading...
                                                        </span>
                                                    </span>
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CryptoAddWallet;

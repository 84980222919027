import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { copyClipboard } from '../../../utils/clipboard'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { UpdateWallets } from '../../../api/wallet'
import { toast } from 'react-toastify'


function TableTrader({ wallets }) {

    async function onClickUpdateWalletStatus(id, current_status) {
        try {
            const body = {
                status: !current_status
            }
            await UpdateWallets(id, body)
            toast.success("successfully ! Update Wallet Status")
        } catch (error) {
            toast.danger(error)
        }
    }

    let render_wallets = !wallets.data ? "loading" : wallets.data.map((item) => (
        <tr key={item.id}>
            <th scope="row">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                </div>
            </th>
            <td className="customer_name">
                <i onClick={() => copyClipboard(item.id)} style={{ cursor: "pointer" }} className="ri-file-copy-line"></i> {item.id}
            </td>
            <td>{item.address}</td>
            <td>
                {item.qrcode ? <a className='link-info' href='#' onClick={() => window.open(item.qrcode)} >#Link</a> : ""}
            </td>
            <td>{item.network}</td>
            <td>0.00</td>
            <td style={{ "width": "100px" }}>
                {/* <div className="form-check form-switch mb-3"> */}
                {/* <Input className="form-check-input" type="checkbox" role="switch" id="SwitchCheck1" defaultChecked /> */}
                <BootstrapSwitchButton
                    checked={item.is_active}
                    onlabel='Active'
                    offlabel='Inactive'
                    onChange={(checked) => {
                        onClickUpdateWalletStatus(item.id, !checked)
                    }}
                    size="sm"
                    width={100}
                    onstyle='success'
                    offstyle='danger'
                    style="border"
                />
                {/* </div> */}
            </td>
            {/* <td>{item.isactive ? "active" : "inactive"}</td> */}
            <td>{item.created_at}</td>
            <td>{item.created_by}</td>
            <td>
                <button className='btn btn-warning btn-sm'>
                    Edit
                </button>
            </td>
        </tr>
    ))

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">Crypto Wallet List</h4>
            </CardHeader>

            <CardBody>
                <div id="customerList">
                    <div className="table-responsive table-card mb-1">
                        <table className="table align-middle table-nowrap" id="customerTable">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col" style={{ width: "50px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                        </div>
                                    </th>
                                    <th className="sort" data-sort="wallet_id">Wallet ID</th>
                                    <th className="sort" data-sort="address">Address</th>
                                    <th className="sort" data-sort="address">QRCODE</th>
                                    <th className="sort" data-sort="balance">Network</th>
                                    <th className="sort" data-sort="balance">Balance</th>
                                    <th className="sort" data-sort="status">Status</th>
                                    <th className="sort" data-sort="created">Created At</th>
                                    <th className="sort" data-sort="created">Created By</th>
                                    <th className="sort" data-sort="created">Action</th>
                                </tr>
                            </thead>
                            <tbody className="list form-check-all">
                                {render_wallets}
                            </tbody>
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                            <div className="text-center">
                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                </lord-icon>
                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                    orders for you search.</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="pagination-wrap hstack gap-2">
                            <Link className="page-item pagination-prev disabled" to="#">
                                Previous
                            </Link>
                            <ul className="pagination listjs-pagination mb-0"></ul>
                            <Link className="page-item pagination-next" to="#">
                                Next
                            </Link>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default TableTrader